import { Link } from 'react-router-dom'

const Public = () => {
    const content = (
        <section className="public">
            <header lassName="public__header">
                <h1>Hello to <span className="nowrap">zimatek.ch</span></h1>
            </header>
            <main className="public__main">
                
                    <table className="table__public">
                    <thead className="table__public__thead">
                        <tr>
                            <th scope="col" className="table__public__th">zi</th>
                            <th scope="col" className="table__public__th">ma</th>
                            <th scope="col" className="table__public__th">tek</th>
                        </tr>
                    </thead>
                    <tbody className="table__public__tbody">
                        <tr>
                            <th scope="col" className="table__public__th">Familie</th>
                            <th scope="col" className="table__public__th">Hobby</th>
                            <th scope="col" className="table__public__th">Technik</th> 
                        </tr>                     
                    </tbody>
                </table>

            </main>
            <footer className="public__footer">
                <Link to="/login">Login</Link>
                <Link style={{marginLeft: '65px'}} onClick={(e) => {window.location.href ='mailto:info@zimatek.ch';}}>info@zimatek.ch</Link>
            </footer>
        </section>

    )
    return content
}
export default Public